
/*
background: linear-gradient(270deg, #62ddbd, #ec6532);
background-size: 400% 400%;

-webkit-animation: AnimationName 30s ease infinite;

@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
 Nice gradient animation*/



/* body {
  font-family: 'Noto Sans', sans-serif;

}

p {
  font-family: 'Open sans', sans-serif;
} */
/* .button {
  font-family: 'Open sans', sans-serif;
} */
/* 
.nav {
  justify-content: center;
  margin-top: 3em;
  margin-left: 1em;
  margin-right: 1em;
  display: flex;
} */

.top-container {
  display:flex;
}

.inner-nav {

  display: inline-block;
  display: flex;
  flex-direction: row;
  overflow:auto;
}

.inner-inner-nav {
  display: flex;
  flex-direction: column;

}

.notlast {
  border-width: 1px 0px 0px 0px;
}

.last {
  border-width: 1px 0px 1px 0px;
}
.logo {
  
  font-weight: 200;
  flex-grow: 1;
  font-size: 25px;
  margin-bottom: 10px;
}
.wa {
  font-family: "Noto Serif JP", sans-serif;

}

.logo-name {
  font-family: "Open Sans", sans-serif;
 
}
.nav-item {
  transition: background-color 0.5s ease-out;
  font-size: 1.2rem;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-top: 0.3em;
  font-weight: 200;
  flex-grow: 1;
  border-style: solid;


  padding-bottom: 0.3em;
  
}
.nav-item:hover {
  cursor: pointer;
}

.not-selected {
  border-right-width:1px;
  background: #bbb;

}

.content {
  
  flex-grow: 1;
  position:relative;
}





.buttons {
  display: flex;
  justify-content: center;
  bottom:2rem;
  left:0px;
  right:0px;
  position:absolute;
}

.open-button {
  font-size: 1.2rem;
  padding: 0.3rem 0.5rem 0.3rem 0.7rem;
}

.what {
  box-sizing:border-box;
  line-height:1.3;
}

.mobile {
  display:none;
}
@media only screen and (max-width: 700px) {
  .mobile {
    display:block;
  }
  .desktop {
    display:none;
  }
}