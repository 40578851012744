@keyframes fade-start-animation {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}
.bodyDiv {
  font-family: "Open Sans", sans-serif;
  background-color:var(--bg-color);
  margin: 0;
  animation: fade-start-animation 1s;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
}

.navtop {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  margin-left: 50px;
  
  
}

.topleftTitle {
  
  font-size: 35.5px;
  font-weight: 700;
}

.transparentText {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
  font-weight: 400;
  padding-top: 2px;
}
.signin {
  
  font-size: 15pt;
  font-weight: 400;
  padding-top: 2px;
  margin-right: 30px;
}

video {
  
  position: absolute;
  width: 100vw;
  height: 100vw;
}


/* .ingame-screenshot {
  background-image: url("/topfrontpage.png");
  background-size: contain;
  flex-basis: 0;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
} */

.tagLineContainer {
  display: flex;
  justify-content:center;
  align-items: center;
  flex-basis: 0;
  height: 100%;
  margin-bottom:100px;
}


.tag-line-sub-container {
  
  min-width:700px;
  flex-basis: 0;
}

.tagLine {
  margin:0px;
  font-size: 2.0em;
  color: #444;
  font-weight: 300;
}

.sub-tagline {
  font-size: 1.0em;
  opacity:0.7;
  color: #444;
  font-weight: 300;
}


.tagline-and-button {
  display: flex;
  margin-top: 50px;
  align-items:center;
}

.secondPart {
  
  position: relative;
  z-index: 3;
  margin: 0;
  margin-top:50px;
}
.second-part-tag-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.secondPartTagLine {
  text-align: center;
  font-weight: 300;
  max-width: 800px;
  font-size:16pt;
  padding: 0px 12px 0px 12px;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #0f283b;
}

.arrow-top {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid black;
}


.topImage {
  min-height:80vh;
  display:flex;
  justify-content:center;
  align-items:center;
}

.testimonialContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  position: relative;
  z-index: 10;
  padding-bottom: 40px;
}

.testimonial-constrainer {
  max-width: 800px;
    box-shadow:var(--shadow-3);

  display: flex;
  align-items: center;
}

.testimonial-avatar {
  display:block
  
}

.creator-avatar {
  border-radius: 1000px;
  height: 180px;
}

.testimonial-text {
  margin-bottom:25px;
  font-size: 15px;
  margin-bottom:15px;
  
}

.testimonial-right {
  
  
  
  padding: 50px;
}

.testimonial-name {
  margin-top: 7.5px;
  
}
.bold-name {
  font-weight: bold;
}

.testimonials {
  margin-bottom: 15rem;
}

.instructor-paragraph-container {
  width: 100%;
  margin-top: 50px;

  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  margin-bottom: 60px;
  z-index: 5;
}

.instructor-paragraph {
  width: 500px;
  text-align: center;
}
.word-creator {
  font-weight: 300;
}

.paragraph-creator {
  text-align: left;
  border: 1px solid;
  border-radius: 2px;
  font-size: 15px;
  padding: 50px;
  
}
.joinus {
  cursor: pointer;
  
  border-width: 0px;
  border-radius: 5px;
  text-align: center;
  margin-right: 50px;
  margin-left: 50px;
  padding-bottom: 1px;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 8px;
  font-weight: 400;

  border-style: inset;
}

.topjoinus-container {
  flex-basis: 0;
  
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.startButton {
  margin-left:60px;
}
.topjoinus {
  cursor: pointer;
  
  border-width: 1px;
  border-radius: 5px;
  
  border-style: solid;
  text-align: center;
  padding: 10px;
  font-weight: 100;
  font-size: 22pt;
}

.topright {
  
  font-size: 16pt;
  font-weight: 100;
}

.testimonial-video-container {
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  
  
  padding-bottom: 100px;
  position: relative;
}

.youtube-frame {
  margin-top: 150px;
  border: 1px solid #000;
  border-radius: 5px;
}
.deeper-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.row-element {
  width:300px;
  box-shadow:var(--shadow-3);
  margin-top: 150px;
  text-align: center;
  margin-left: 100px;
  margin-right: 100px;

  border: 1px solid #bbb;
  padding-bottom: 17px;
}

.margin-top {
  margin-top: 100px;
}

.bottom-title {
  font-size: 16pt;
  margin-bottom: 20px;
  
}

.bottom-testimonial-video {
  font-family: "Open Sans", sans-serif;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  text-decoration: none;
  
  padding: 8px;
  margin-top: 10px;
  font-size: 12pt;
  font-weight: 400;
  border-color: #000;
  background-color: transparent;
}
.bottom-desc {
  max-width: 300px;
  min-height: 80px;
  padding: 0px 5px 0px 5px;
}
.fun-img {
  border-bottom:1px solid #bbb;
  background-position: center;
  
  
  margin: 0px;
  padding:0px;
  
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.school-tested-img {
  background-position: center;
  margin: 0px;
  border-bottom:1px solid #bbb;
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.critical-skill-img {
  background-position: center;
  border-bottom:1px solid #bbb;
  margin: 0 auto;
  margin-bottom: 15px;
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.beginner-friendly-img {
  background-position: center;
  
  border-bottom:1px solid #bbb;
  margin: 0 auto;
  margin-bottom: 15px;
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.bottom-most-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
  margin-left: 50px;
  margin-bottom: 100px;
  margin-top: 10px;
  flex-direction: column;
}

.bottom-most-button-text {
  position: relative;
  z-index: 10;
  
  margin-bottom: 50px;
  font-size: 15pt;
}

.bottom-most-button {
  position: relative;
  z-index: 10;
  font-size: 14pt;
  
  
  font-size: 15pt;
  padding: 10px;
  border-width: 0px;
  border-radius: 5px;
  text-align: center;
}
.pointer {
  cursor: pointer;
}
.front-blanket {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);

  z-index: 5;
  transition-property: opacity;
  transition-duration: 1s;
  animation: front-fadein 0.6s;
}
.front-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: front-slidein 0.6s;
}

.inner-modal {
  position: relative;
}
.close-button {
  cursor: pointer;
  color: #fff;
  font-size: 18pt;
  position: absolute;
  right: 0;
  top: 0;
}

.iframe-css {
  margin: 26px;
}

@keyframes front-slidein {
  from {
    transform: translateY(-200%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes front-fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.copyright-container {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8pt;
  color: rgba(0, 0, 0, 0.5);

  flex-direction: column;
}

.bigwa {
  font-family: "Noto Serif JP", sans-serif;

  
}

.testimonialImageBorder {
  border-right: 1px solid #bbb;
  width:200px;
}

.innerTestimonial {
  display:flex;
  
  
        border: 1px solid #bbb;
}

@media only screen and (max-width: 700px) {
  .navtop {
    margin-left:0px;
    margin-top:70px;
    display:flex;
    justify-content:center;
  }
  .tagline-and-button {
   display: block;
   
}

  .iframe-css {
    margin: 0px;
    max-width:100%;
  }
  
  .startButton {
   margin-bottom:50px;
  }
  .tag-line-sub-container {
    min-width:250px;
  }
  .tagLineContainer {
    display:flex;
    flex-direction:column-reverse;
    align-items:center;
    justify-content:center;
  }
  .innerTestimonial {
    display: flex;
    flex-direction:column;
    border: 1px solid black;

  }
  

  .testimonialImageBorder {
    width:auto;
    border-right: none;
    border-bottom: 1px solid black;
  }
  
  
  
.sub-tagline {
  font-size: 0.8em;
  text-align:center;
}

  .tagLine {
    font-size: 13pt;
    text-align: center;
    
  }
  .transparentText {
    display: none;
  }
}

.continue-as-guest {
  background-color: #fff;
  border-width: 0;
  text-decoration: underline;
  cursor: pointer;
}

.signup-or-guest-button {
  width: 5em;
  font-size: 12pt;
  height: 3em;
  margin: 3em;
}

.signup-or-guest {
  width: 35em;
  height:15em;
  margin: 3em 2em 0em 2em;
}

