.signup-or-guest-button {
  width: 5em;
  font-size: 12pt;
  height: 3em;
  margin: 3em;
}

.signup-or-guest {
  width: 15em;
  margin: 3em 2em 0em 2em;
}

.topjoinus {
  cursor: pointer;
  background-color: #e3e3e3;
  color: #444;
  border-width: 1px;
  border-radius: 5px;
  border-color: #444;
  border-style: solid;
  text-align: center;
  padding: 10px;
  font-weight: 100;
  font-size: 22pt;
}
.signup-blanket {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba(255, 255, 255, 0.5);
}

.signup-modal-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 300000000000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.signup-modal-bubble {
  color:#323232;
  background-color: var(--bg-color);
  box-shadow:var(--shadow-3);
  border: 1px solid #bbb;
  position: relative;
  border-radius: 1px;
  max-width:100%;
  animation: fade-in 0.5s;
}

.signup-modal-inner-bubble {
  padding-left: 5em;
  padding-right: 5em;
  padding-top: 2em;
  padding-bottom: 2em;
  
}

.signup-column {
  display:flex;
  flex-direction:column;
}

.signup-input {
  border-radius: 1px;
  border:1px solid;
  margin-top: 3pt;
  padding: 18pt;
  font-size: 12pt;
  background-color: var(--bg-color);
  outline: none;
}

.or-container {
  display: flex;
  align-items: center;
  margin-top: 12pt;
  margin-bottom: 12pt;
}

hr {
  display: block;
  position: relative;
  padding: 0;
  margin: 8px auto;
  height: 0;
  width: 100%;
  max-height: 0;
  font-size: 1px;
  line-height: 0;
  clear: both;
  border: none;
  border-top: 1px solid #aaaaaa;
  border-bottom: 1px solid #ffffff;
}
.or-class {
  margin-left: 10px;
  margin-right: 10px;
  color: #666;
  font-size: 12pt;
}

.signup-submit {
  width: 100%;
  
  padding: 12pt;
  border-radius: 2px;
  font-size: 14pt;
  font-weight: 500;
  letter-spacing: 1pt;
  font-family: "Roboto", sans-serif;
  border-width: 0;
  margin-top: 12pt;
}

.subtle-signup-submit {
  background-color: #ff6d8d;
  padding: 6pt;
  color: white;
  border-radius: 4px;
  font-size: 10pt;
  font-weight: 700;
  letter-spacing: 1pt;
  font-family: "Roboto", sans-serif;
  border-width: 0;
  margin-top: 18pt;
}

.signup-title {
  text-align: center;
  color: black;
  font-size: 18pt;
  margin-bottom: 1em;
}

.google-button {
  height: 40px;
  border-width: 0;
  background: white;
  color: #737373;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding: 0;
}

.google-button__icon {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0 8px 8px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.google-button__icon--plus {
  width: 27px;
}

.google-button__text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 24px;
  font-size: 14px;
  font-weight: bold;
}

.google-container {
  display: flex;
  justify-content: center;
}

.forgotpassword-container {
  background-color: #eee;
  cursor: pointer;
  padding-top: 15pt;
  padding-bottom: 15pt;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-close-button {
  cursor: pointer;
  color: #777;
  font-size: 14pt;
  position: absolute;
  right: 5pt;
  top: 5pt;
}
.subtle-signup-submit-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.left-margin-span {
  margin-left: 5pt;
}
