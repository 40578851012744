.nofocus:focus {
      outline: none;

}

.action-button {
  transition: box-shadow .1s,transform .17s,-webkit-box-shadow .1s,-webkit-transform .17s;
  padding: 5px;
  color:#323232;
  box-shadow: var(--shadow-3);
  
   border: 0.75px solid #bbb;
   background-color: #fff;
   margin-bottom: 10px;
        border-radius: 30px;

  }


.squareButton {
    transition: box-shadow .1s,transform .17s,-webkit-box-shadow .1s,-webkit-transform .17s;
    padding: 10px;
    color:#323232;
     background-color: #fff;
     border-radius: 30px;
     
  cursor: pointer;
 box-shadow: var(--shadow-3);
   border: 0.75px solid #bbb;

        font-family: 'Open Sans', sans-serif;

}



.squareButtonInv {
    transition: box-shadow .1s,transform .17s,-webkit-box-shadow .1s,-webkit-transform .17s;
    padding: 5px;
    color:#323232;
     background-color: #fff;
     cursor: pointer;
    outline: none;
    border-radius: 30px;
    font-family: 'Open Sans', sans-serif;
     background-color: #fff;

box-shadow: var(--shadow-3);
   border: 0.75px solid #bbb;

    
}


.squareButtonInvSelected {
    transition: box-shadow .1s,transform .17s,-webkit-box-shadow .1s,-webkit-transform .17s;
    padding: 5px;
    box-shadow: -2px 2px 0 #7f7f7f;
   border: 0.75px solid #bbb;
     background-color: #fff;
     
     position:relative;
     /* top: -6px;
     left:6px; */
     /* transform: translate(18px,0px); */
     transition: background-color 0.5s, opacity 0.5s;
     background-color:#e6f1f2;
     cursor: pointer;
outline: none;
border-radius: 30px;
    font-family: 'Open Sans', sans-serif;


    box-shadow: var(--shadow-3);
   border: 1px solid #bbb;

}


.squareButtonSpaced {
    transition: box-shadow .1s,transform .17s,-webkit-box-shadow .1s,-webkit-transform .17s;
    padding: 10px;
    color:#323232;
     background-color: #fff;
    margin:5px 5px 5px 0px;
    border-radius: 30px;
    font-family: 'Open Sans', sans-serif;
     cursor: pointer;

box-shadow: var(--shadow-3);
   border: 0.75px solid #bbb;

}

.squareInput {
  height:39.5px;
  box-sizing: border-box;
    border: 1px solid #bbb;
    
    padding:5px;
        font-family: 'Open Sans', sans-serif;

}



.squareInputSpaced {
    border: 1px solid;
    border-radius: 1px;
    margin:25px;
    padding:5px;

}



.simpleTextarea {
    border:1px solid black;
    transition: box-shadow 0.5s;
     /* border-style: none;  */
    
}
.simpleTextarea:focus {
    outline:none;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

@keyframes darkenAnimation {
    0% {
      opacity:0;
      background-color:#0005;
    }
    100% {
      opacity:1;
      background-color:#0005;
    }
  }
  

  @keyframes fade-in {
    0% {
      opacity:0;
      
    }
    100% {
      opacity:1;
      
    }
  }

  @keyframes fade-in {
    0% {
      opacity:0;
      
    }
    100% {
      opacity:1;
      
    }
  }

  @keyframes fade-in-tooltip {
    0% {
      opacity:0;
      
    }
    50% {
        opacity: 0;
    }
    100% {
      opacity:1;
      
    }
  }

  @keyframes input-focus-animation {
    0% {
      background-color:  #fff;
    }
    20% {
      background-color:  #ddd;
      
    }
    40% {
      background-color:  #fff;
      
        }
    60% {
      background-color:  #ddd;
      
    }
    80% {
      background-color:  #fff;
      
    }
  }

  .focus-attention-input {
    animation: input-focus-animation 1s;
  }

.fadeIn {
    animation: fade-in 1s;
}
.fadeInFast {
    animation: fade-in 0.5s;
}


.fadeInTooltip {
    animation: fade-in-tooltip 1s ease-out;
}



@keyframes lightenAnimation {
  from {
    opacity: 0
  }
  to {
    opacity:0.5
  }
}



@keyframes button-click-pulse {
  0% {
    /* background-color:white; */
    transform: scale(1)
  }
  10% {
    transform: scale(1.1)
    /* background-color:#e6f1f1; */
  }
  100% {
    transform: scale(1)
    /* background-color:white; */
  }
}

/* button:active {
} */

.squareButton:active {
    outline: none;
    animation-name: none!important;

    /* animation-name: none; */
}

.squareButtonSpaced:active {
    outline: none;
    animation-name: none!important;

    

}

.squareButton:focus {
    outline: none;
    animation: button-click-pulse 0.5s;
}

.squareButtonSpaced:focus {
    outline: none;
    animation: button-click-pulse 0.5s;

    /* background-color:#e6f1f1; */

}


.action-button:focus {
    outline: none;
    animation: button-click-pulse 0.5s;
}

.action-button:focus {
    outline: none;
    animation: button-click-pulse 0.5s;

    /* background-color:#e6f1f1; */

}





.squareButtonInv:focus {
    outline: none
}



.squareButtonInvSpaced:focus {
    outline: none
}


.invertedBoxShadow {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px inset, rgba(0, 0, 0, 0.23) 0px 6px 6px inset;
}


@keyframes change-font-size {
    0% {
      color: black;
      left:0px;
    }
    50% {
      color: #bbb;
      left:4px;
      
    }
    100% {
      color: black;
      left:0px;
      
    }
  }
  
.centerspaced {
  margin:30px;
  display:flex;
  justify-content: center;

}

.flashy {
    
    position:relative;
    animation:  change-font-size 3s infinite;

}


.mobile {
  display:none;
}

.paper-container {
  display:flex;
  justify-content:center;
  width:100vw;
  max-width:100%;
}

 .paper {
     padding: 30px;
      width: 1200px;
      box-sizing:border-box;
       margin-top: 50px;
        margin-bottom: 150px;
         box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
         
  }


  .video-tool {
    position:static;
    
  max-width:100%;
  max-height:100%
}

.startButton {
    margin-left:50px
  }

.small-button {
  padding:2px 8px 2px 8px;
}

.plusCross {
  height: 2.5ch;
  width: 2.5ch;
}

.popupModalBox {
  width:400px
}

@media only screen and (max-width: 1200px) {
 .paper {
      width: 800px;
     
         
  }
}
.frontPageTopText {
   font-size: 12pt;
    letter-spacing: 2.5px;
     margin-bottom: 100px;
      font-weight: 600;
       text-align: center;
}
@media only screen and (max-width: 700px) {
  .frontPageTopText { 
      padding: 0px 20px 0px 20px;
        font-weight: 400;

  }
  .paper {
      width: 500px;
     
         
  }
  .popupModalBox {
  width:100%;
  
}

  .plusCross {
  height: 4ch;
  width: 4ch;
}

.startButton {
    margin-left:0px;
  }

  
  
  .mobile {
    display:block;
  }

  .paper-container {
  display:block;
  
}


  .paper {
     margin:15px 15px 15px 15px;
     max-width:auto;

     

  }

  .desktop {
    display:none;
  }
}


.video-container {
position: relative;
padding-bottom: 56.25%;
padding-top: 30px; height: 0; overflow: hidden;
}
 
.video-container iframe,
.video-container object,
.video-container embed {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}

@keyframes fade-top-border-animation{
  0% {
    border-top:1px solid black;
  }
  50% {
    border-top:1px solid black;
  }
  95% {
    border-top:1px solid rgba(0, 0, 0, 0)
  }
  100% {
    border-top:1px solid black;
  }
  
}

.prompt-blanket {
  position: fixed;
  left:0px;
  right:0px;
  top:0px;
  bottom:0px;
  background: rgba(255, 255, 255, 0.7);
  animation: fadein 0.3s;
  z-index: 2000000;
   display: flex;
  align-items: center;
  justify-content: center;
}



.movable-card {
  color: #323232;
  
  border: 1px solid #bbb;
  
  position: fixed;
  z-index: 20000001;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  background-color: var(--light-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  min-height: 100px;
  min-width: 100px;
  border-radius: 1px;
}



